// ReservationItem.js
import React from 'react';
import { format } from 'date-fns';

const ReservationItem = ({ reservation, onDelete }) => {
  const formattedStartDate = format(new Date(reservation.dateRange[0]), 'dd/MM/yyyy');
  const formattedEndDate = format(new Date(reservation.dateRange[reservation.dateRange.length - 1]), 'dd/MM/yyyy');

  return (
    <div className="reservation-item">
      <div className="reservation-details">
        <div className="reservation-detail">Dates: {`${formattedStartDate} - ${formattedEndDate}`}</div>
        {reservation.names.every((name) => !name.trim()) ? (
          <div className="reservation-detail">Aucun nom enregistré</div>
        ) : (
          <div className="reservation-detail">Personnes : {reservation.names.filter((name) => name.trim()).join(', ')}</div>
        )}
        <div className="reservation-detail">Nombre de personnes : {reservation.peopleCount}</div>
      </div>
      <button onClick={onDelete} className="delete-button">Supprimer</button>
    </div>
  );
};

export default ReservationItem;
