// MyCalendar.js
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { db } from './firebase';
import { collection, getDocs, doc, setDoc, deleteDoc } from "firebase/firestore";
import { isSameDay, format } from "date-fns";
import './MyCalendar.css';
import ReservationItem from './ReservationItem';

const fetchData = async () => {
  const querySnapshot = await getDocs(collection(db, "reservations"));
  const reservationsData = [];
  
  querySnapshot.docs.forEach((doc) => {
    // Récupérez l'identifiant du document
    const docId = doc.id;
    // Ajoutez l'identifiant à la data de la réservation
    const reservationData = { ...doc.data(), docId };
    reservationsData.push(reservationData);
  });
  
  return reservationsData;
};

const MyCalendar = ({ onDateChange }) => {
  const [date, setDate] = useState([new Date(), new Date()]);
  const [reservations, setReservations] = useState([]);
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [showReservations, setShowReservations] = useState(false);
  
  const updateData = async () => {
    const reservationsData = await fetchData();
    setReservations(reservationsData);
  };

  const handleDeleteReservation = async (index) => {
    // Créez une copie de la liste des réservations
    const updatedReservations = [...selectedReservations];
    
    // Récupérez la réservation à supprimer
    const deletedReservation = updatedReservations.splice(index, 1)[0];
  
    // Assurez-vous que l'identifiant du document est défini
    if (!deletedReservation.docId) {
      console.error("L'identifiant du document de la réservation est manquant.");
      return;
    }
  
    // Mettez à jour l'état avec la nouvelle liste
    setSelectedReservations(updatedReservations);
  
    // Supprimez la réservation de la collection d'origine
    const originalCollectionRef = collection(db, "reservations");
    const originalDocRef = doc(originalCollectionRef, deletedReservation.docId);
  
    // Transférez la réservation vers une nouvelle collection (par exemple "archivedReservations")
    const newCollectionRef = collection(db, "archivedReservations");
    const newDocRef = doc(newCollectionRef, deletedReservation.docId);
  
    try {
      // Supprimez l'enregistrement de la collection d'origine
      await deleteDoc(originalDocRef);
  
      // Ajoutez l'enregistrement à la nouvelle collection
      await setDoc(newDocRef, deletedReservation);
  
      console.log("Réservation archivée avec succès.");
  
      // Mettez à jour le calendrier après la suppression
      const updatedData = await fetchData();
      setReservations(updatedData);
    } catch (error) {
      console.error("Erreur lors de l'archivage de la réservation :", error);
    }
  };
    

  useEffect(() => {
    const fetchReservations = async () => {
      const reservationsData = await fetchData();
      setReservations(reservationsData);
    };

    fetchReservations(); // Charge les réservations dès le montage du composant
  }, []);

  useEffect(() => {
    const updateSelectedReservations = () => {
      const selected = reservations.filter((reservation) => {
        for (const dateSelec of reservation.dateRange) {
          const startInRange = isSameDay(date[0], new Date(dateSelec));
          const endInRange = isSameDay(date[1], new Date(dateSelec));
  
          // Vérifie si la date de début ou de fin de la réservation est dans la sélection
          if (startInRange || endInRange) {
            return true;
          }
  
          // Vérifie si la réservation est complètement incluse dans la sélection
          const startDate = new Date(dateSelec);
          const endDate = new Date(dateSelec);
          endDate.setDate(endDate.getDate() + 1);
  
          if (startDate >= date[0] && endDate <= date[1]) {
            return true;
          }
        }
        return false;
      });
      setSelectedReservations(selected);
    };
  
    if (showReservations) {
      updateSelectedReservations();
    }
  }, [date, showReservations]);

  const onChange = (value) => {
    setDate(value);
    onDateChange(value);
    setShowReservations(true);
  };

  const clearSelection = () => {
    setDate([new Date(), new Date()]);
    setShowReservations(false);
  };

  const showIndicative = ({ date, view }) => {
    if (view === "month") {
      const value = reservations.reduce((acc, reservation) => {
        for (const dateSelec of reservation.dateRange) {
          if (isSameDay(date, new Date(dateSelec))) {
            return acc + reservation.peopleCount;
          }
        }
        return acc;
      }, 0);

      if (value !== 0) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: 'blue', color: 'white' }}>
            {value}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className='calendar-container mx-auto w-64 bg-white rounded-lg shadow-md p-4'>
      <Calendar 
        onChange={onChange} 
        value={date} 
        selectRange
        tileContent={showIndicative}
        className='mx-auto'
      />
      <button onClick={clearSelection} className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
        Effacer la sélection
      </button>

      {showReservations && (
        <div className="text-center">
          <h2>Réservations:</h2>
          <div>
            {selectedReservations.map((reservation, index) => (
              <ReservationItem
                key={index}
                reservation={reservation}
                onDelete={() => handleDeleteReservation(index)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyCalendar;