// PersonCounter.js
import React, { useState } from 'react';
import './PersonCounter.css';

const PersonCounter = ({ onValidate }) => {
  const [peopleCount, setPeopleCount] = useState(1); // Au moins une personne
  const [personNames, setPersonNames] = useState(['']); // Tableau pour stocker les noms

  const increment = () => {
    setPeopleCount(peopleCount + 1);
    setPersonNames([...personNames, '']); // Ajouter un nouvel élément vide pour le nouveau champ
  };

  const decrement = () => {
    if (peopleCount > 1) {
      setPeopleCount(peopleCount - 1);
      setPersonNames(personNames.slice(0, -1)); // Supprimer le dernier élément du tableau
    }
  };

  const handleNameChange = (index, newName) => {
    const updatedNames = [...personNames];
    updatedNames[index] = newName;
    setPersonNames(updatedNames);
  };

  const validate = () => {
    onValidate(peopleCount, personNames);
  };

  return (
    <div className='counter-container mx-auto w-64 bg-white rounded-lg shadow-md p-4'>
      <button onClick={decrement} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-l'>-</button>

      <span className='px-4'>{peopleCount} personne(s)</span>

      <button onClick={increment} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r'>+</button>

      <div>
        {personNames.map((name, index) => (
          <input
            key={index}
            type='text'
            placeholder={`Nom de la personne ${index + 1}`}
            value={name}
            onChange={(e) => handleNameChange(index, e.target.value)}
            className='mt-2 p-2 border rounded person-input'
          />
        ))}
      </div>

      <button onClick={validate} className='mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'>Valider</button>
    </div>
  );
};

export default PersonCounter;
