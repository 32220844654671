// Title.js
import React from 'react';
import './style.css';

const Title = () => {
  return (
    <div className="title-container text-center">
      <h1 className="text-4xl font-bold">Réservation Manigod</h1>
    </div>
  );
};

export default Title;