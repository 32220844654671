// SelectedDate.js
import React from 'react';
import { format } from 'date-fns';
import './style.css'; // Créez un fichier SelectedDate.css

const SelectedDate = ({ date }) => {
  const formatDate = (date) => {
    return date.toDateString();
  };

  let dateDisplay = '';
  if (Array.isArray(date)) {
    dateDisplay = `${format(date[0], 'dd/MM/yyyy')} - ${format(date[1], 'dd/MM/yyyy')}`;
  } else {
    dateDisplay = formatDate(date);
  }

  return (
    <div className='centered-text'>
      <p>
        <span className='font-bold'>Dates sélectionnées:</span> {' '}
        {dateDisplay}
      </p>
    </div>
  );
};

export default SelectedDate;
