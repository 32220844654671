// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAvtroR5uIGsyhC-lVwh0RNCopDEbf_kus",
  authDomain: "planifease.firebaseapp.com",
  projectId: "planifease",
  storageBucket: "planifease.appspot.com",
  messagingSenderId: "48643931010",
  appId: "1:48643931010:web:dfbcd23c050455abd72d9b"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db }; // Exportez db de cette façon