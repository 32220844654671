// App.js
import React, { useState } from 'react';
import Title from './Title';
import MyCalendar from './MyCalendar';
import updateData from './MyCalendar';
import SelectedDate from './SelectedDate';
import PersonCounter from './PersonCounter';
import { collection, addDoc } from "firebase/firestore"; 
import { db } from './firebase';
import './App.css';
import { SpeedInsights } from "@vercel/speed-insights/react"

const App = () => {
  const [date, setDate] = useState([new Date(), new Date()]);
  const [showCounter, setShowCounter] = useState(false);
  const [peopleCount, setPeopleCount] = useState(0);

  const onDateChange = (value) => {
    setDate(value);
    setShowCounter(true);
  };

  const onValidate = async (count, names) => {
    setPeopleCount(count);
    setShowCounter(false);
    
    const tabDates = createDatesArray(date)
    console.log(tabDates);

    // Envoyer les données à Firebase
    const docData = {
      dateRange: tabDates,
      peopleCount: count,
      names: names, // Ajoutez les noms des personnes ici
    };
    try {
      const docRef = await addDoc(collection(db, "reservations"), docData);
      console.log("Document written with ID: ", docRef.id);
      updateData();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const goToToday = () => {
    console.log('go to today');
    const today = new Date();
    setDate(today);
  };

  return (
    <div className='app'>
      <Title />
      <MyCalendar onDateChange={onDateChange} />
      <SelectedDate date={date} />
      {showCounter && <PersonCounter onValidate={onValidate} />}
      {!showCounter && <p className='text-center mt-4'>Vous avez ajouté {peopleCount} personne(s)</p>}
    </div>
  );
};

function createDatesArray(dateRange) {
  const startDate = dateRange[0];
  const endDate = dateRange[1];
  const datesArray = [];

  // On clone la date de début pour pouvoir la modifier sans affecter la date d'origine
  let currentDate = new Date(startDate);

  // On ajoute chaque date au tableau jusqu'à atteindre la date de fin
  while (currentDate <= endDate) {
    datesArray.push(new Date(currentDate).toString());
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesArray;
}

export default App;
